<template>
	<el-container class="layout-login">
		<slot />
	</el-container>
</template>

<style>
.layout-login {
	padding: 40px;
	background-color: #304156;
	min-height: 100vh;
}
</style>
